import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`En forma esfèrica de fins a 10 cm de diàmetre. La superfície externa és membranosa i dura, de color blanquinós i brut. Una vegada madur s’obre fins a la meitat formant a la part superior làmines triangulars girades cap a fora. La part interna o gleva primer és blanquinosa i després es converteix en una pols marró formada per espores. Aquestes són marrons en massa, subgloboses, de 12 x 8-10,5 micres i són disseminades per una obertura que es troba a la part superior de l’envolta.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      